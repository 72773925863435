import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grow,
  makeStyles,
  Slide,
  Theme,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import {
  GenericLoadingSpinner,
  YZThemeProvider,
} from "@yardzen-inc/react-common";
import Cookies from "js-cookie";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CenterFullScreen, DefaultHeader, HeaderBase } from "../../components";
import CreateAccountForm from "../../components/checkout/CreateAccountForm";
import GoBackButton from "../../components/checkout/GoBack";
import PropertyAddressForm from "../../components/checkout/PropertyAddressForm";
import { sendBeginCheckoutEvent } from "../../components/checkout/util/sendBeginCheckoutEvent";
import {
  AnonymousUserControlWrapper,
  useDesignProfileCtx,
} from "../../components/designProfile";
import { useDataLayer } from "../../data";
import { useAppSelector } from "../../hooks";
import { setAccountCreated, setAddress } from "../../slices/checkoutSlice";
import {
  setProductStyle,
  setSelectedProduct,
  setSelectedSku,
} from "../../slices/productSlice";
import { UserCtx } from "../../util";
import {
  IAddressObject,
  parseGeocodeLocationToAddress,
} from "../../util/functions/parseGeocodeLocationToAddress";
import { useAvailableStates } from "../../util/functions/useAvailableStates";
import { useSegment } from "../../util/Segment";
import { getPackageAndSkuFromUrlParams } from "./utils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: "center",
    maxWidth: "600px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
    },
  },
}));

export const PackageCreateAccountPage = () => {
  const { designProfile } = useDesignProfileCtx();
  const history = useHistory();
  const classes = useStyles();
  const availableStates = useAvailableStates();
  const userContext = React.useContext(UserCtx);
  const dataLayer = useDataLayer();
  const beginCheckoutEventSentRef = useRef(false);
  const dispatch = useDispatch();
  const segment = useSegment();
  const urlParams = new URLSearchParams(window.location.search);
  const byProxy = urlParams.get("byProxy") === "true";
  const quizEmailCookieRef = useRef<string>();
  const designQuizAddressRef = useRef<IAddressObject | null>(null);

  useEffect(() => {
    const emailCookie = document.cookie
      .split("; ")
      .find(row => row.startsWith("yz-dp-quiz-email="))
      ?.split("=")[1];

    if (emailCookie) {
      quizEmailCookieRef.current = decodeURIComponent(emailCookie);
    }

    let quizAddressCookie = document.cookie
      .split("; ")
      .find(row => row.startsWith("yz-dp-quiz-address="))
      ?.split("=")[1];

    if (quizAddressCookie) {
      quizAddressCookie = decodeURIComponent(quizAddressCookie);
      const quizAddress = JSON.parse(quizAddressCookie);
      if (
        quizAddress &&
        quizAddress.location &&
        quizAddress.location?.address_components
      ) {
        designQuizAddressRef.current = parseGeocodeLocationToAddress(
          quizAddress.location
        );
        dispatch(setAddress({ address: designQuizAddressRef.current }));
      }
    }
  }, [dispatch]);

  // For now, this page has 3 entry points:
  // 1. Design Profile Flow (Existing)
  // 2. Package Flow (Existing)
  // 3. New website package page (New)
  // Eventually when the source of truth for packages is on the marketing site, only #3 will need to
  // be supported. While the agency is developing the packages page they need to be able to test, so
  // #3 needs to work but the production flows will still ONLY be #1 and #2.

  const {
    accountCreated,
    addressAddedInDesignProfile,
    address,
  } = useAppSelector(state => state.checkout);
  const { selectedProduct, selectedSku, products } = useAppSelector(
    state => state.products
  );
  // Check to see if the URL has package data
  const skuAndProductFromUrl = getPackageAndSkuFromUrlParams(
    products,
    urlParams
  );
  if (skuAndProductFromUrl.product && skuAndProductFromUrl.sku) {
    dispatch(
      setSelectedProduct({ selectedProduct: skuAndProductFromUrl.product })
    );
    dispatch(setSelectedSku({ selectedSku: skuAndProductFromUrl.sku }));
    if (skuAndProductFromUrl.packageStyle) {
      dispatch(
        setProductStyle({ packageStyle: skuAndProductFromUrl.packageStyle })
      );
    }

    const productObject = {
      sku: skuAndProductFromUrl.sku.id,
      name: skuAndProductFromUrl.product.name,
      price: (skuAndProductFromUrl.sku.price / 100).toString(),
      category: skuAndProductFromUrl.sku.attributes.name,
      packageStyle: skuAndProductFromUrl.packageStyle,
    };

    if (!beginCheckoutEventSentRef.current) {
      sendBeginCheckoutEvent({
        dataLayer,
        productObject,
      });

      segment.trackAddToCart({
        sku_id: skuAndProductFromUrl.sku.id,
        amount: skuAndProductFromUrl.sku.price,
        product_name: skuAndProductFromUrl.sku.metadata.product,
        product_details: skuAndProductFromUrl.product.name,
        product_style: skuAndProductFromUrl.packageStyle,
        items: [
          {
            product_id: skuAndProductFromUrl.sku.id,
            price: skuAndProductFromUrl.sku.price,
            quantity: 1,
          },
        ],
        fbp: Cookies.get("_fbp") || null,
        fbc: Cookies.get("_fbc") || null,
        email: userContext?.email,
        city: address.city,
        state: address.state,
        zip: address.zip,
      });

      beginCheckoutEventSentRef.current = true;
    }
  }

  const [addressError, setAddressError] = React.useState<string>("");
  const isLoggedIn = userContext && !userContext.isAnonymous;

  const handleSubmitPropertyAddress = () => {
    const { street, city, zip, state } = address;

    if (street.trim() && city.trim() && zip.trim() && state.trim()) {
      if (availableStates.includes(state)) {
        if (byProxy) {
          history.push("/purchase?byProxy=true");
        } else {
          history.push("/purchase");
        }
      } else {
        return setAddressError("We are not designing in your state yet");
      }
    } else {
      return setAddressError("Please complete address form");
    }
  };

  if (!products || !products.length) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  if (
    (!skuAndProductFromUrl.product || !skuAndProductFromUrl.sku) &&
    (!selectedProduct || !selectedSku)
  ) {
    window.location.href = `${process.env.REACT_APP_WORDPRESS_BASE_URL}/packages`;
  }

  if (byProxy || (isLoggedIn && addressAddedInDesignProfile)) {
    if (byProxy) {
      history.push("/purchase?byProxy=true");
    } else {
      history.push("/purchase");
    }
  }

  return (
    <AnonymousUserControlWrapper>
      <YZThemeProvider>
        <HeaderBase>
          <DefaultHeader />
        </HeaderBase>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={3}
          mb={3}
        >
          <Slide in={true}>
            <Grow in={true}>
              <div className={classes.root}>
                <GoBackButton
                  goBack={() => {
                    const packageName = urlParams.get("package");
                    const lotSizeName = urlParams.get("lotsize") || "standard";
                    const queryString = packageName
                      ? `${packageName}?lotsize=${lotSizeName}`
                      : "";

                    window.location.href = `${process.env.REACT_APP_WORDPRESS_BASE_URL}/${queryString}`;
                  }}
                />
                <Accordion expanded={!userContext || !userContext.email}>
                  <AccordionSummary>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      Account Details
                      {!!userContext && !!userContext.email && (
                        <Check style={{ color: "green" }} />
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ justifyContent: "center" }}>
                    <CreateAccountForm
                      prefilledEmail={
                        designProfile?.contactInformation?.email ??
                        quizEmailCookieRef.current ??
                        ""
                      }
                      style={{ height: "unset" }}
                      goBack={() => history.goBack()}
                      nextStep={() => {
                        dispatch(setAccountCreated({ accountCreated: true }));
                      }}
                      noBackButton
                      noAccountsWithExistingProfile
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={!!userContext?.email || accountCreated}>
                  <AccordionSummary>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      Property Address
                      {!!userContext && !!userContext.email && (
                        <Check style={{ color: "green" }} />
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <PropertyAddressForm
                        propertyAddress={address}
                        setAddress={(address: IAddressObject) => {
                          dispatch(setAddress({ address }));
                        }}
                        handleSubmitPropertyAddress={() => {
                          handleSubmitPropertyAddress();
                        }}
                        userEmail={userContext?.email ?? ""}
                        errorMessage={addressError}
                      />
                    </>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grow>
          </Slide>
        </Box>
      </YZThemeProvider>
    </AnonymousUserControlWrapper>
  );
};
